<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <base-table ref="base_table" :page="page" @create="create" @edit="edit"/>
      </v-col>
    </v-row>
    <create-person ref="create_person" modal_name="create_person" action="create_employee" user_type="employee" @refresh="reload"/>
  </v-container>
</template>

<script>
import BaseTable from "../../components/commonComponents/BaseTable";
import CreatePerson from "../people/Create";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseTable,
    BaseBreadcrumb,
    CreatePerson,
  },
  data() {
    return {
      page: helpFunctions.pages.employees,
    };
  },
  methods: {
    create() {
      this.$refs.create_person.openModal('create_person');
    },
    edit(item) {
      this.$router.push("/" + this.page.name + "/" + item.id);
    },
    reload() {
      this.$refs.base_table.run();
    },
  },
};
</script>
